<template>
  <v-container>
    <h2>Selbstauskünfte</h2>
    <v-divider class="mb-5"></v-divider>
    <v-btn to="/disclosure/new">NEW</v-btn>
  </v-container>
</template>

<script>
export default {}
</script>
